<template>
    <interior-page-content-container
      nav-selection=""
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="true"
      :loading="loading"
    >

      <template #title>
        {{checklist.name}}
      </template>

      <template #content>
        <div class="flex flex-col self-center pb-2">
          <img
            :src="checklist.picture.url"
            class="object-scale-down h-40 mt-3 bg-transparent rounded-md w-72 md:w-80 lg:w-96"
            alt="Checklist Image"
          >
          <progress-bar
            :percentage="progress"
            class="mt-4"
          />
          <checklist-item-component
            v-for="(item) in checklist.items" :key="item.name"
            @update:complete="toggleComplete({id: item.id, post: $event})"
            @update:wishlist="toggleWishlist({id: item.id, post: $event})"
            :name='item.name'
            :url='item.url'
            :description='item.description'
            :complete.sync="item.completed"
            :wishlist.sync="item.onWishlist"
            :disable="projectPermissions === 'basic'"
            class="mt-4"
          />
        </div>
      </template>

    </interior-page-content-container>
</template>

<script>
import {
  PROJECT_GET,
  CHECKLIST_GET,
  CHECKLIST_ITEM_COMPLETE,
  CHECKLIST_ITEM_INCOMPLETE,
  CHECKLIST_ITEM_ON_WISHLIST,
  CHECKLIST_ITEM_NOT_ON_WISHLIST,
  USER_ADD_POINTS,
} from '@/store/actions';

import {
  PROJECT_PERMISSIONS_CURRENT,
} from '@/store/getters';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import ProgressBar from '@/components/shared/general/ProgressBar.vue';
import ChecklistItemComponent from '@/components/projects/checklists/ChecklistItemComponent.vue';

export default {
  name: 'Checklist',
  components: {
    InteriorPageContentContainer,
    ProgressBar,
    ChecklistItemComponent,
  },
  data() {
    return {
      loading: true,
      checklist: {
        name: '',
        type: 'Safety',
        items: [],
      },
      projectPermissions: '----',
    };
  },
  computed: {
    progress() {
      if (this.loading) return 0;
      let complete = 0;
      this.checklist.items.forEach((items) => {
        complete += items.completed ? 1 : 0;
      });
      return (complete / this.checklist.items.length) * 100;
    },
  },
  async created() {
    this.$store.dispatch(CHECKLIST_GET, {
      projectId: this.$route.params.project_id,
      checklistId: this.$route.params.checklist_id,
    })
      .then((checklist) => {
        this.checklist = checklist;
        this.checklist.items = this.checklist.items.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        });
      })
      .finally(() => { this.loading = false; });

    this.$store.dispatch(PROJECT_GET, this.$route.params.project_id)
      .catch(() => this.$router.push({ name: 'PageNotFound' }))
      .finally(() => {
        this.projectPermissions = this.$store.getters[PROJECT_PERMISSIONS_CURRENT];
      });
  },
  methods: {
    backNav() {
      this.$router.go(-1);
    },
    toggleComplete(data) {
      const r = Math.random() * 1000;
      setTimeout(() => {
        this.$store.dispatch(data.post
          ? CHECKLIST_ITEM_COMPLETE
          : CHECKLIST_ITEM_INCOMPLETE, {
          projectId: this.$route.params.project_id,
          checklistId: this.$route.params.checklist_id,
          checklistItemId: data.id,
        })
          .then(() => {
            const completedCount = this.checklist.items.filter((item) => item.completed).length;
            if (completedCount === this.checklist.items.length) {
              this.$store.dispatch(USER_ADD_POINTS, 'Complete Checklist');
            }
          });
      }, r);
    },
    toggleWishlist(data) {
      const r = Math.random() * 1000;
      setTimeout(() => {
        this.$store.dispatch(data.post
          ? CHECKLIST_ITEM_ON_WISHLIST
          : CHECKLIST_ITEM_NOT_ON_WISHLIST, {
          projectId: this.$route.params.project_id,
          checklistId: this.$route.params.checklist_id,
          checklistItemId: data.id,
        });
      }, r);
    },
  },
};
</script>

<style>

</style>
