<template>
  <div
    @click="$emit('click')"
    class="`
      flex flex-row border flex-grow-0 flex-shrink-0 border-contrast
      rounded-lg w-72 md:w-80 lg:w-96 bg-backfill cursor-default
      active:shadow-none
      checklist-item-component
    `"
  >
    <div class="flex flex-col items-start w-full px-2">

      <div class="flex items-center w-full mt-2">
        <check-box
          @click="completeToggle"
          :checked="complete"
          :color="'green'"
          class="flex-shrink-0 mr-2 toggle-complete"
          :disable="disable"
        />
        <p class="flex-grow font-bold text-left text-primary">{{name}}</p>
        <div
          @click="open = !open"
          class="flex justify-end ml-2 cursor-pointer text-content toggle-expansion"
        >
          <chevron-up v-if="open" />
          <chevron-down v-else />
        </div>
      </div>

      <div
        class="w-full mb-2 overflow-hidden transition-all duration-500 ease-in-out max-h-0"
        :class="{'max-h-960' : open}"
      >
        <div class="w-full p-2 font-bold text-left text-content">
          {{description}}
        </div>
        <div
          v-if="url && url.length > 0"
          class="flex justify-end w-full"
        >

          <shopping-cart
            @click="openLink"
            class="pr-2 cursor-pointer text-content item-link"
          />
          <wishlist-mark
            @click="wishlistToggle"
            :class="[
              wishlist ? 'text-red-500 selected' : 'text-content',
              {'cursor-pointer': !disable}]
            "
            class="toggle-wishlist"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import ChevronUp from 'vue-material-design-icons/ChevronUp.vue';
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
import WishlistMark from 'vue-material-design-icons/Heart.vue';
import ShoppingCart from 'vue-material-design-icons/Cart.vue';

import CheckBox from '@/components/shared/inputs/CheckBox.vue';

export default {
  name: 'ChecklistItemComponent',
  components: {
    ChevronUp,
    ChevronDown,
    WishlistMark,
    ShoppingCart,
    CheckBox,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: true,
    },
    complete: {
      type: Boolean,
      required: true,
    },
    wishlist: {
      type: Boolean,
      required: true,
    },
    disable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    openLink() {
      if (this.url.toLowerCase().substring(0, 4) === 'http') {
        window.open(this.url, '_blank');
      } else {
        window.open(`https://${this.url}`, '_blank');
      }
    },
    completeToggle() {
      this.$emit('update:complete', !this.complete);
      this.$emit('update');
    },
    wishlistToggle() {
      if (!this.disable) {
        this.$emit('update:wishlist', !this.wishlist);
        this.$emit('update');
      }
    },
  },
};
</script>

<style>

</style>
